<template>
  <div class="reset-password">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>忘记密码</span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="form-field">
        <div class="label">账号</div>
        <van-field
          v-model="username"
          clearable
          placeholder="请输入账号"
        ></van-field>
      </div>
      <div class="flexbox align-center form-field">
        <van-field
          v-model="code"
          type="digit"
          clearable
          :maxlength="6"
          placeholder="请输入验证码"
        >
        </van-field>
        <van-button
          type="info"
          class="code-btn"
          :disabled="!username || counting"
          @click="onSend"
        >
          <van-count-down
            :time="60000"
            v-if="counting"
            @finish="counting = false"
          >
            <template #default="timeData">
              <span class="block">重新获取({{ timeData.seconds }})</span>
            </template>
          </van-count-down>
          <span v-else>获取验证码</span>
        </van-button>
      </div>
      <div class="label">新密码</div>
      <password-field
        v-model="password"
        clearable
        placeholder="请输入新密码"
      ></password-field>
      <van-button
        class="submit"
        type="info"
        @click="onSubmit"
        :disabled="!valid"
      >
        确 定
      </van-button>
    </main>
  </div>
</template>
<script>
import { sendSMSCode, resetPassword } from '@/api/account.js';
export default {
  data() {
    return {
      username: '',
      password: '',
      code: '',
      counting: false,
    };
  },
  computed: {
    valid() {
      return this.username && this.password;
    },
  },
  methods: {
    onSend() {
      this.counting = true;
      sendSMSCode({ tel: this.username }).then(() => {
        this.$toast.success('发送成功');
      });
    },
    onSubmit() {
      this.$toast.loading();
      resetPassword({
        username: this.username,
        password: this.password,
        code: this.code,
      })
        .then(res => {
          this.$toast.clear();
          if (res.Result) {
            this.$router.replace({ name: 'Login' });
          } else {
            this.$toast.fail(res.Info);
          }
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-header {
  font-size: 21px;
}
.reset-password {
  background-color: #fff;
}
.page-body {
  padding: rem(60px);
}
.label {
  font-size: 16px;
  margin-bottom: rem(30px);
}
.van-field {
  border-radius: rem(25px);
  background-color: #f7f7fc;
  font-size: 16px;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
}
.form-field {
  margin-bottom: rem(40px);
}
.submit {
  height: rem(100px);
  line-height: rem(100px);
  width: 100%;
  border-radius: rem(50px);
  margin-top: rem(156px);
  font-size: 16px;
}
.van-count-down {
  color: #fff;
}
.code-btn {
  border-radius: rem(25px);
  margin-left: rem(20px);
  white-space: nowrap;
  color: #fff;
}
</style>
